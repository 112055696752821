import $ from 'jquery';
import Cookies from 'js-cookie';

$(() => {
    if ($('.toplayer-container').length && !Cookies.get('toplayer')) {
        $('html').addClass('toplayer-active');

        $('.toplayer-container').on('click', () => {
            $('.toplayer-container').remove();
            $('html').removeClass('toplayer-active');
            Cookies.set('toplayer', 'wyświetlony', { expires: 1 });
        });
    }
});
