export default {
    prefix: 'custom',
    iconName: 'conditions',
    icon: [
        75.052,
        81.53,
        [],
        null,
        'M60.277 0a2.953 2.953 0 00-2.882 3.009 14.624 14.624 0 01-.5 4.316 7.852 7.852 0 01-6.555 5.541v-.7a1.925 1.925 0 00-1.922-1.923h-6.756v2h6.678v14.67h-3.576v2h3.654a1.925 1.925 0 001.922-1.922v-.59h2.426a14.613 14.613 0 014.24.9l.672.21a18.65 18.65 0 005.6.886h3.367a3.028 3.028 0 003.025-3.023v-1.168a3.028 3.028 0 002.1-2.879v-1.348a3.028 3.028 0 001.671-2.705V15.9a3.024 3.024 0 00-1.416-5.697H64.25A14.4 14.4 0 0062.973 1.6 2.953 2.953 0 0060.277 0zm.077 1.995a.92.92 0 01.841.525 12.423 12.423 0 011.024 7.682h-.557v2h10.363a1.024 1.024 0 010 2.049h-3.369v2h2.785v1.025a1.026 1.026 0 01-1.023 1.024h-3.367v2h2.719v1.027a1.026 1.026 0 01-1.026 1.023H65.38v2h2.29v1.022a1.026 1.026 0 01-1.024 1.025H63.28v-.01a16.739 16.739 0 01-5.015-.798l-.66-.201a16.389 16.389 0 00-4.834-.987h-2.43v-9.523a9.862 9.862 0 008.472-6.998 16.655 16.655 0 00.583-4.91.908.908 0 01.712-.938.92.92 0 01.247-.037zM9.627 21.775a5.425 5.425 0 00-5.33 5.417v19.04h2l.002-19.04a3.422 3.422 0 013.418-3.418h31.388v-2H9.715a5.425 5.425 0 00-.088 0zm47.228 7.841v16.615h2V29.616zm-33.25 4.941a4.6 4.6 0 00-4.587 4.6v9.8H5.242A5.248 5.248 0 000 54.2v27.33h12.021v-6.4a3.631 3.631 0 013.627-3.627h32a3.631 3.631 0 013.627 3.627v6.4h12.026V54.2a5.248 5.248 0 00-5.242-5.242H44.28l.002-9.8a4.6 4.6 0 00-4.6-4.6H23.618a4.6 4.6 0 00-.012 0zm.012 2h16.065a2.6 2.6 0 012.6 2.6v9.8H21.017v-9.8a2.6 2.6 0 012.6-2.6zm-18.375 14.4H58.06A3.246 3.246 0 0161.3 54.2v25.33h-8.026l.004-4.4a5.634 5.634 0 00-5.627-5.627h-32a5.634 5.634 0 00-5.627 5.627v4.4H2V54.2a3.246 3.246 0 013.242-3.242z'
    ]
};
