import $ from 'jquery';
import flatpickr from 'flatpickr';
import 'flatpickr/dist/l10n/pl';

const form = document.getElementById('registration_form');
const orderForm = document.getElementById('order_form');

let visitDateFlatpickr;
let contactDateFlatpickr;

const visitDateField = document.getElementById('Preferowany_termin_wizyty_pub');
const contactDateField = document.getElementById(
    'Preferowany_termin_kontaktu_pub'
);

function displayDate(selectedDates, dateStr, instance, itemId) {
    if (typeof selectedDates[0] !== 'undefined') {
        const day = `0${selectedDates[0].getDate()}`.slice(-2);
        const month = `0${selectedDates[0].getMonth() + 1}`.slice(-2);
        const year = selectedDates[0].getFullYear();
        const dateString = `${year}-${month}-${day}`;
        document.getElementById(itemId).value = dateString;
    }
    instance.element.nextElementSibling.classList.add('input-selected');
}

function initDatePickers() {
    visitDateFlatpickr = flatpickr('#Preferowany_termin_wizyty_pub', {
        locale: 'pl',
        minDate: 'today',
        disableMobile: 'true',
        dateFormat: 'Y-m-d',
        allowInput: true, // enable html5 validation
        altInputClass: 'invisible',
        onChange(selectedDates, dateStr, instance) {
            displayDate(
                selectedDates,
                dateStr,
                instance,
                'Preferowany_termin_wizyty'
            );
        },
        onReady(selectedDates, dateStr, instance) {
            displayDate(
                selectedDates,
                dateStr,
                instance,
                'Preferowany_termin_wizyty'
            );
        },
    });
    contactDateFlatpickr = flatpickr('#Preferowany_termin_kontaktu_pub', {
        locale: 'pl',
        minDate: 'today',
        disableMobile: 'true',
        dateFormat: 'Y-m-d',
        allowInput: true, // enable html5 validation
        altInputClass: 'invisible',
        onChange(selectedDates, dateStr, instance) {
            displayDate(
                selectedDates,
                dateStr,
                instance,
                'Preferowany_termin_kontaktu'
            );
        },
        onReady(selectedDates, dateStr, instance) {
            displayDate(
                selectedDates,
                dateStr,
                instance,
                'Preferowany_termin_kontaktu'
            );
        },
    });
}

function customFloatingLabel() {
    const visitDateInput = $(visitDateField);
    const contactDateInput = $(contactDateField);
    const visitDateFloatingLabel = $(
        'label[for="Preferowany_termin_wizyty_pub"]'
    );
    const contactDateFloatingLabel = $(
        'label[for="Preferowany_termin_kontaktu_pub"]'
    );

    if (visitDateInput.val()) {
        visitDateFloatingLabel.addClass('custom-floating-label');
    }

    if (contactDateInput.val()) {
        contactDateFloatingLabel.addClass('custom-floating-label');
    }

    visitDateInput.on('focus', () => {
        visitDateFloatingLabel.addClass('custom-floating-label');
    });

    contactDateInput.on('focus', () => {
        contactDateFloatingLabel.addClass('custom-floating-label');
    });

    visitDateInput.on('blur', () => {
        if (!visitDateInput.val()) {
            visitDateFloatingLabel.removeClass('custom-floating-label');
        }
    });

    contactDateInput.on('blur', () => {
        if (!contactDateInput.val()) {
            contactDateFloatingLabel.removeClass('custom-floating-label');
        }
    });
}

function initDatePickersOrder() {
    // Set predefined values if form has errors
    $.ajax({
        url: '/api/v1/medical_examination/dates',
        type: 'POST',
        data: {
            medicalExamination: $('.medical-examination').val(),
        },
    })
        .done(function (e) {
            runDatePicker(e.data, false);
        })
        .fail(function (e) {
            runDatePicker([]);
        });

    if ($('#Preferowany_termin_wizyty_pub').val()) {
        console.log();
        resetTimeSelect();

        $.ajax({
            url: '/api/v1/medical_examination/dates',
            type: 'POST',
            data: {
                medicalExamination: $('.medical-examination').val(),
                dateVisit: $('#Preferowany_termin_wizyty_pub').val(),
            },
        })
            .done(function (e) {
                updateTimeSelect(e.data, $('#old_time_select').val());
            })
            .fail(function (e) {
                resetTimeSelect();
            });
    }

  

    if (null != $('.medical-examination').val() ) {
        $('.medical-examination').trigger('change');
        $('.invalid-tooltip-examination').fadeIn(); 
        $('#Preferowany_termin_wizyty_pub').on('change', function (e) {
            resetTimeSelect();

            $.ajax({
                url: '/api/v1/medical_examination/dates',
                type: 'POST',
                data: {
                    medicalExamination: $('.medical-examination').val(),
                    dateVisit: $('#Preferowany_termin_wizyty_pub').val(),
                },
            })
                .done(function (e) {
                    updateTimeSelect(e.data);
                })
                .fail(function (e) {
                    resetTimeSelect();
                });
        });

        $('.medical-examination').on('change', function (e) {

            if ('' == $('.medical-examination').val()){ 
                $('.invalid-tooltip-examination').fadeIn(); 
            }else{  
                $('.invalid-tooltip-examination').fadeOut();
            }
            resetTimeSelect();
            resetDateSelect();

            $.ajax({
                url: '/api/v1/medical_examination/dates',
                type: 'POST',
                data: {
                    medicalExamination: $('.medical-examination').val(),
                },
            })
                .done(function (e) {
                    runDatePicker(e.data);
                })
                .fail(function (e) {
                    runDatePicker([]);
                });
        });        
    }
}

function runDatePicker(elements = [], autoOpen = true) {
    let enableArray = [];
    $.each(elements, function (e, t) {
        enableArray.push(t.medicalExaminationDay);
    }),
        (visitDateFlatpickr = flatpickr('#Preferowany_termin_wizyty_pub', {
            locale: 'pl',
            minDate: 'today',
            disableMobile: false,
            dateFormat: 'Y-m-d',
            allowInput: true, // enable html5 validation
            altInputClass: 'invisible',
            enable: enableArray,
            onChange(selectedDates, dateStr, instance) {
                displayDate(
                    selectedDates,
                    dateStr,
                    instance,
                    'Preferowany_termin_wizyty'
                );
            },
            onReady(selectedDates, dateStr, instance) {
                displayDate(
                    selectedDates,
                    dateStr,
                    instance,
                    'Preferowany_termin_wizyty'
                );
            },
        }));

    if (enableArray.length > 0 && autoOpen) {
        visitDateFlatpickr.open();
    }
}

function updateTimeSelect(e, selectedValue = false) {
    let timeSelect = $('.time-select');

    timeSelect.append('<option value="">Wybierz godzinę</option>');
    timeSelect.attr('disabled', false);
    $.each(e, function (e, n) {
        let selected = '';

        if (selectedValue == n.medicalExaminationHour) {
            selected = 'selected';
        }

        timeSelect.append(
            '<option value="' +
                n.medicalExaminationHour +
                '"' +
                selected +
                '>' +
                n.medicalExaminationHour.replace(/:00$/, '') +
                '</option>'
        );
    });
}

var intervalId;
var errorCounter = 0;

function initOrderChecker() {
    let $alert = $($('.alert-checker').get(0));

    if ($alert && $alert.data('id') && $alert.data('hash')) {
        intervalId = setInterval(function () {
            orderCheckAjax($alert);
        }, 3000);
    }
}

function orderCheckAjax($alert) {
    $.ajax({
        url: '/rejestracja-online/check',
        method: 'POST',
        data: {
            id: $alert.data('id'),
            hash: $alert.data('hash'),
        },
        success: function (response) {
            let status = $.parseJSON(response).status;

            if (status === 'paid') {
                clearInterval(intervalId); // Stop the timer
                $('.alert-checker .response').html(
                    'Transakcja została opłacona prawidłowo. W celu anulowania rezerwacji należy skontaktować się pod numerem telefonu <a href="tel:71724 2222">71 724 22 22</a>.'
                );
                $('.alert-checker')
                    .addClass('alert-success')
                    .removeClass('alert-info');
            } else {
                errorCounter++;
            }
        },
        error: function (error) {
            errorCounter++;
        },
        complete: function () {
            if (errorCounter > 10) {
                $('.alert-checker .response').html(
                    'System nie zwrócił informacji dot. płatności. Informacja o transakcji zostanie przesłana mailowo. W przypadku braku płatności termin nie zostanie zarezerwowany.'
                );
                $('.alert-checker')
                    .addClass('alert-danger')
                    .removeClass('alert-info');
                clearInterval(intervalId);
            }
        },
    });
}

function initInvoices() {
    $('#Faktura_VAT').on('change', function (e) {
        if ($(this).is(':checked')) {
            $('#NIP').attr('required', true);
        } else {
            $('#NIP').attr('required', false);
        }
    });

    $('#Faktura_VAT').trigger('change');
}

function resetTimeSelect() {
    $('.time-select').empty();
}

function resetDateSelect() {
    $('.time-select').empty();
}

if (form) {
    initDatePickers();
    customFloatingLabel();
}

if (orderForm) {
    initDatePickersOrder();
    customFloatingLabel();
    initOrderChecker();
    initInvoices();
}
