export default {
    prefix: 'custom',
    iconName: 't',
    icon: [
        76.613,
        90.785,
        [],
        null,
        'M56.105 0l-3.4 6.893-7.603 1.105 5.5 5.365L49.3 20.94l6.812-3.576 6.8 3.576-1.3-7.576 5.5-5.365-7.607-1.105zm-.003 4.521l2.072 4.2 4.637.674-3.356 3.27.793 4.618-4.146-2.18-4.149 2.18.791-4.619-3.353-3.27 4.634-.673zm13.931 8.12L68 16.758l-4.545.662 3.29 3.205-.778 4.527 4.066-2.136 4.067 2.136-.778-4.525 3.291-3.207-4.545-.662zm.006 4.517l.7 1.43 1.576.226-1.141 1.112.27 1.57-1.41-.74-1.41.74.27-1.568-1.14-1.114 1.584-.226zm-36.738 3.795A33.333 33.333 0 000 54.253a33.322 33.322 0 0016.969 29.022l-1.219 5.51h-2.955v2h41.93v-2H50.14L49 83.63a33.159 33.159 0 0017.602-29.375 33.334 33.334 0 00-33.301-33.3zm-.197 2a31.332 31.332 0 01.2 0 31.333 31.333 0 0131.3 31.3A31.17 31.17 0 0148.55 81.599l-2.145-9.694h3.905a3.456 3.456 0 003.45-3.45 3.456 3.456 0 00-3.45-3.452H15.246a3.456 3.456 0 00-3.451 3.451 3.456 3.456 0 003.451 3.451h4.24l-2.064 9.325A31.318 31.318 0 012.004 54.254a31.332 31.332 0 0131.1-31.3zm-.127 6.104A24.806 24.806 0 008.645 49.186a25.214 25.214 0 00-.008.035 24.806 24.806 0 00-.006.033 25.214 25.214 0 00-.508 5 24.929 24.929 0 002.635 11.213l1.789-.893a22.958 22.958 0 01-2.322-8.117h8.279l-.104-1.102a14.789 14.789 0 01-.08-1.521 14.682 14.682 0 0114.678-14.662l1.145.023v-8.11a23.21 23.21 0 0122.35 23.169 22.944 22.944 0 01-2.425 10.32l1.791.893a24.946 24.946 0 002.633-11.213A25.213 25.213 0 0033.39 29.068zm-.844 2.043v6.091a16.683 16.683 0 00-15.824 16.643c0 .207.005.414.011.623h-6.11c-.007-.207-.01-.414-.016-.623a22.8 22.8 0 01.352-3.957A23.211 23.211 0 0132.133 31.1zM15.197 67.006a1.453 1.453 0 01.05 0H50.31a1.453 1.453 0 011.45 1.451 1.453 1.453 0 01-1.45 1.451H15.246a1.453 1.453 0 01-1.451-1.451 1.453 1.453 0 011.402-1.451zm6.336 4.898H44.36l3.735 16.881H17.799zM23.3 77.17v2h6.367v-2zm-.494 3.799v2h6.367v-2zm-.934 3.908v2h6.367v-2z'
    ]
};
