import $ from 'jquery';
import throttle from 'lodash/throttle';

const registerBtn = $('.register-btn');

const activate = () => {
    if (
        $(window).scrollTop() + $(window).height() <
        $(document).height() - 100
    ) {
        registerBtn.addClass('active');
    } else {
        registerBtn.removeClass('active');
    }
};

$(() => {
    activate();
    $(window).on('scroll', throttle(activate, 300));

});
