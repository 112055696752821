export default {
    prefix: 'custom',
    iconName: 'angle-down',
    icon: [
        5.573,
        8.635,
        [],
        null,
        'M5.383 4.775L1.714 8.444a.645.645 0 01-.914 0l-.61-.61a.645.645 0 010-.914l2.6-2.6-2.6-2.6a.645.645 0 010-.914L.797.191a.645.645 0 01.914 0L5.38 3.858a.646.646 0 01.003.917z'
    ]
};
