/**
 * Smooth scrolls the page to the `#a-{hash}` element.
 * Works also when coming from another page.
 */
import $ from 'jquery';
import 'jquery.easing';

function highlight(element) {
    if (element.length && element.is('h1, h2, h3, h4, h5, h6')) {
        element.addClass('highlight');
        setTimeout(() => {
            element.addClass('highlight-fade-out');
        }, 2000);
        setTimeout(() => {
            element.removeClass('highlight-fade-out');
            element.removeClass('highlight');
        }, 4000);
    }
}

const scroll = (element, customAdditionalOffset = 0) => {
    if ($(element).length) {
        let offsetTop = $(element).offset().top;
        const additionalOffset = 20 + customAdditionalOffset;

        let headerHeightDiff = 0;
        if ($(window).width() > 991) {
            headerHeightDiff = 147 - 74;
        }

        // Consider the scrolled fixed header.
        if ($('.scrolled .is-fixed').length) {
            offsetTop -= $('.is-fixed').outerHeight() + additionalOffset;
        }

        // Consider the fixed header when page is not scrolled.
        if ($('html:not(.scrolled) .is-fixed').length) {
            offsetTop -= $('.is-fixed').outerHeight() + additionalOffset - headerHeightDiff;
        }

        // Consider the concrete5 toolbar.
        if ($('html').hasClass('ccm-toolbar-visible')) {
            offsetTop -= $('#ccm-toolbar').outerHeight();
        }

        $('html, body').animate(
            {
                scrollTop: offsetTop
            },
            750,
            'easeOutQuart'
        );

        highlight($(element));
    }
};

$(() => {
    // Jump, if clicked element has a corresponding anchor on this page.
    $('a[href*="#"]').on('click', e => {
        const selector = `#a-${$(e.currentTarget)
            .attr('href')
            .split('#')
            .pop()}`;

        if ($(selector).length) {
            e.preventDefault();
            scroll($(selector).get(0));
        }
    });

    // Jump, if ID in hash passed in current location, has a corresponding anchor on this page.
    if (window.location.hash && $(`#a-${window.location.hash.split('#').pop()}`).length) {
        setTimeout(() => {
            scroll($(`#a-${window.location.hash.split('#').pop()}`).get(0));
        }, 750);
    }
});

export default scroll;
