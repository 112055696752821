export default {
    prefix: 'custom',
    iconName: 'tag',
    icon: [
        42,
        81.609,
        [],
        null,
        'M21 0c-.55 0-1 .45-1 1v6.61h-7.56c-1.25 0-2.44.46-3.37 1.3l-7.44 6.77A5.029 5.029 0 000 19.38v57.23c0 2.75 2.24 5 5 5h32c2.76 0 5-2.24 5-5V19.27c0-1.32-.54-2.61-1.47-3.55l-6.71-6.66a4.938 4.938 0 00-3.52-1.45H22V1c0-.55-.45-1-1-1zm-8.56 9.61H20v9.1a4.999 4.999 0 001 9.9c2.76 0 5-2.24 5-5 0-2.41-1.72-4.43-4-4.9v-9.1h8.3c.8 0 1.55.31 2.11.87l6.71 6.66c.56.56.88 1.34.88 2.13v57.34c0 1.65-1.35 3-3 3H5c-1.65 0-3-1.35-3-3V19.38c0-.84.36-1.65.98-2.22l7.44-6.77c.55-.5 1.27-.78 2.02-.78zM20 20.79V23c0 .55.45 1 1 1s1-.45 1-1v-2.21c1.16.41 2 1.51 2 2.82 0 1.65-1.35 3-3 3s-3-1.35-3-3c0-1.3.84-2.4 2-2.82z'
    ]
};
