import { Swiper, Navigation, Pagination, Lazy, Autoplay, A11y } from 'swiper/core';
import ssm from '../ssm';

Swiper.use([Navigation, Pagination, Lazy, Autoplay, A11y]);

let sliderFeatures;

const initSlider = function() {
    sliderFeatures = new Swiper('#slider-features', {
        speed: 800,
        autoplay: {
            delay: 6000
        },
        // Lazy load
        preloadImages: false,
        lazy: {
            checkInView: true,
            loadPrevNext: true,
            loadPrevNextAmount: 1
        },
        pagination: {
            el: '#slider-features-pagination',
            type: 'bullets',
            clickable: true,
            renderBullet(index, className) {
                return `<span class='${className}'><span class='swiper-pagination-bullet-inner'></span></span>`;
            }
        },
        slidesPerView: 2,
        slidesPerGroup: 2,
        spaceBetween: 16,
        breakpoints: {
            576: {
                slidesPerView: 3,
                slidesPerGroup: 3
            },
            768: {
                slidesPerView: 4,
                slidesPerGroup: 4
            },
            992: {
                slidesPerView: 5,
                slidesPerGroup: 5
            },
            1200: {
                slidesPerView: 6,
                slidesPerGroup: 6
            }
        }
    });
};

if (document.getElementById('slider-features')) {
    ssm('xs,sm', () => {
        if (sliderFeatures !== undefined) sliderFeatures.destroy(true, true);
    });

    ssm('md,lg,xl,xxl,xxxl', () => {
        initSlider();
    });
}
