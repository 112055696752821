export default {
    prefix: 'custom',
    iconName: 'phone',
    icon: [
        27.545, 27.537,
        [],
        null,
        'M20.659 27.287q-.165 0-.321-.015c-.262-.026-6.491-.694-12.805-7.007C.969 13.699.298 7.465.273 7.204a4.9 4.9 0 011.286-3.58L4.192.991a2.555 2.555 0 013.606 0l3.642 3.643a2.555 2.555 0 010 3.606l-.87.869a3 3 0 00-.717 2.16 8.49 8.49 0 002.536 3.884 8.474 8.474 0 003.9 2.539 1.649 1.649 0 00.226.014 3.051 3.051 0 001.923-.732l.87-.87a2.554 2.554 0 013.606 0l3.64 3.641a2.551 2.551 0 010 3.605l-2.633 2.633a5.022 5.022 0 01-3.262 1.304zM5.994 1.95a.843.843 0 00-.6.246L2.759 4.83a3.28 3.28 0 00-.793 2.211c.006.056.667 5.917 6.77 12.022 5.857 5.855 11.711 6.512 11.77 6.518a3.3 3.3 0 002.209-.793l2.633-2.633a.852.852 0 000-1.2l-3.64-3.64a.876.876 0 00-1.2 0l-.87.87a4.716 4.716 0 01-3.608 1.2c-.219-.032-2.214-.388-4.842-3.017s-2.984-4.622-3.017-4.84a4.628 4.628 0 011.2-3.608l.87-.87a.854.854 0 000-1.2L6.6 2.207a.84.84 0 00-.606-.257z'
    ]
};
