import $ from 'jquery';
import Mmenu from '../vendor_modified/mmenu';
import ssm from '../ssm';

$(() => {
    const hamburger = $('#main-nav-toggle');
    const menu = new Mmenu(
        '.js-main-nav',
        {
            iconPanels: false,
            backButton: true,
            autoHeight: false,
            extensions: {
                '(min-width: 1px)': [
                    'position-front',
                    'fx-listitems-slide',
                    'multiline',
                    'pagedim-black'
                ],
                '(min-width: 1px) and (max-width: 767px)': ['shadow-panels', 'border-full'],
                '(min-width: 768px)': [
                    'border-none',
                    'fullscreen',
                    'position-right',
                    'fx-menu-slide',
                    'fx-panels-slide-0'
                ]
            },
            navbars: [
                {
                    content: ['prev', 'breadcrumbs', 'close']
                }
            ]
        },
        {
            offCanvas: {
                clone: true,
                page: {
                    selector: '.ccm-page'
                }
            }
        }
    );

    const api = menu.API;

    hamburger.on('click', () => {
        api.open();
    });

    api.bind('open:start', () => {
        hamburger.addClass('is-active');
    });
    api.bind('close:start', () => {
        hamburger.removeClass('is-active');
    });
});
