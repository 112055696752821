import $ from 'jquery';
import { config, library, dom } from '@fortawesome/fontawesome-svg-core';

import {
    faSearchPlus,
    faChevronRight,
    faChevronLeft,
    faChevronDown,
    faPhoneAlt,
    faMapMarkedAlt
} from '@fortawesome/pro-regular-svg-icons';

import {
    faHome,
    faAngleUp,
    faFilePdf,
    faFileWord,
    faFileImage,
    faFile,
    faLink,
    faSitemap,
    faChevronRight as faChevronRightLight,
    faChevronLeft as faChevronLeftLight,
    faTimesCircle,
    faCheckCircle,
    faDownload
} from '@fortawesome/pro-light-svg-icons';

import {
    // concrete5 icons - don't remove.
    faArrows,
    faShareAlt,
    faAngleRight,
    faArrowRight,
    faArrowLeft,
    faSpinnerThird,
    faTimes,
    faSquareFull
} from '@fortawesome/pro-solid-svg-icons';

import {
    faInstagram,
    faYoutube,
    faFacebookF,
    faLinkedinIn
} from '@fortawesome/free-brands-svg-icons';

// Example custom phone icon
// Use it this way: <i class="custom fa-phone"></i>
import phone from './icons/phone';
import email from './icons/email';
import angleDown from './icons/angle-down';

import t from './icons/t';
import conditions from './icons/conditions';
import image from './icons/image';
import note from './icons/note';
import results from './icons/results';
import specialists from './icons/specialists';
import tag from './icons/tag';
import time from './icons/time';

import bones from './icons/bones';
import bowels from './icons/bowels';
import brain from './icons/brain';
import pelvis from './icons/pelvis';
import prostate from './icons/prostate';
import spine from './icons/spine';

config.searchPseudoElements = true;

library.add(
    faArrows,
    faShareAlt,
    faAngleRight,
    faArrowRight,
    faArrowLeft,
    faSpinnerThird,
    faTimes,
    faSquareFull,
    faHome,
    faSearchPlus,
    faAngleUp,
    faChevronRight,
    faChevronLeft,
    faChevronDown,
    faFilePdf,
    faFileWord,
    faFileImage,
    faFile,
    faPhoneAlt,
    faMapMarkedAlt,
    faLink,
    faSitemap,
    faChevronLeftLight,
    faChevronRightLight,
    faTimesCircle,
    faCheckCircle,
    faDownload,
    faInstagram,
    faYoutube,
    faFacebookF,
    faLinkedinIn,
    phone,
    email,
    angleDown,
    t,
    conditions,
    image,
    note,
    results,
    specialists,
    tag,
    time,
    bones,
    bowels,
    brain,
    pelvis,
    prostate,
    spine
);

dom.i2svg({ node: $('.ccm-page').get(0) });

// dom.watch({
//     autoReplaceSvgRoot: $('.ccm-page').get(0),
//     observeMutationsRoot: $('.ccm-page').get(0)
// });

/* eslint-disable import/prefer-default-export */
export { dom };
