import { Swiper, Navigation, Pagination, Lazy, Autoplay, A11y, EffectFade } from 'swiper/core';

Swiper.use([Navigation, Pagination, Lazy, Autoplay, A11y, EffectFade]);

function initSlider() {
    const sliderHome = new Swiper('#slider-home', {
        speed: 800,
        autoplay: {
            delay: 4000
        },
        effect: 'fade',
        // Lazy load
        preloadImages: false,
        lazy: {
            checkInView: true,
            loadPrevNext: true,
            loadPrevNextAmount: 1
        },
        navigation: {
            nextEl: '#slider-home-arrow-next',
            prevEl: '#slider-home-arrow-prev'
        },
        pagination: {
            el: '#slider-home-pagination',
            type: 'bullets',
            clickable: true,
            renderBullet(index, className) {
                return `<span class='${className}'><span class='swiper-pagination-bullet-inner'></span></span>`;
            }
        }
    });
}

if (document.getElementById('slider-home')) {
    initSlider();
}
