export default {
    prefix: 'custom',
    iconName: 'results',
    icon: [
        77.988,
        87.701,
        [],
        null,
        'M57.867 0a20 20 0 00-19.824 19.973h2A17.994 17.994 0 0158.016 2a17.994 17.994 0 0117.972 17.973 17.963 17.963 0 01-16.992 17.945l.108 2a19.965 19.965 0 0018.884-19.945A20 20 0 0058.016 0a20 20 0 00-.149 0zm.12 3.957A16.02 16.02 0 0042.02 19.074c-.017.296-.026.597-.026.9h2c0-.266.007-.53.022-.792a14.021 14.021 0 0114-13.229 14.038 14.038 0 0114.021 14.022 14.037 14.037 0 01-14.021 14.02v2A16.04 16.04 0 0074.04 19.978 16.04 16.04 0 0058.02 3.957a16.02 16.02 0 00-.034 0zm-.971 3.09v2.436h2V7.047zm-7.436 3.078l-1.412 1.414 1.72 1.723 1.415-1.414zm16.871 0l-1.722 1.723 1.414 1.414 1.722-1.723zm-9.435 4.229v6.62h8.732v-2h-6.732v-4.62zm-11.928 4.62v2h2.435v-2zm23.42 0v2h2.435v-2zm-2.365 7.714l-1.414 1.414 1.72 1.72 1.414-1.414zM0 27.266v55.771h3.818v4.664h35.92a11.562 11.562 0 0011.547-11.547V31.733h-4.668v-4.467h-12.54v2H44.62v43.252a1.928 1.928 0 01-1.924 1.924h-4.672v4.671a1.926 1.926 0 01-1.925 1.924H2V29.266h10.541v-2zm16.889 0a2 2 0 00-1.95 2v5.472a3.128 3.128 0 003.149 3.12 3.175 3.175 0 002.9-1.983 2.516 2.516 0 012.323-1.539 2.52 2.52 0 012.322 1.54 3.185 3.185 0 002.933 1.982 3.121 3.121 0 003.116-3.122v-5.47a2 2 0 00-2-2H16.939a2 2 0 00-.05 0zm.052 2h12.745v5.472a1.127 1.127 0 01-1.15 1.12 1.165 1.165 0 01-1.061-.76 4.516 4.516 0 00-4.164-2.762 4.513 4.513 0 00-4.159 2.762 1.176 1.176 0 01-1.091.76 1.12 1.12 0 01-1.12-1.12zm29.676 4.467h2.668v42.421a9.56 9.56 0 01-9.547 9.547H5.818v-2.664H36.1a3.929 3.929 0 003.921-3.924v-2.671h2.672a3.93 3.93 0 003.924-3.924zm-33.87 12.086v2h21.128v-2zm0 5.916v2h21.128v-2zm-.999 6.988v4.982h23.129v-4.982h-2v2.982H13.748v-2.982z'
    ]
};
