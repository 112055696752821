import $ from 'jquery';
import throttle from 'lodash/throttle';
import scroll from '../hash-scroll';

const backToTopButton = $('#back-to-top');

function moveBackToTopButton() {
    const clientWidth = $(document).width();
    const containerOuterWidth = $('.footer-bottom > .container').outerWidth();
    const containerInnerWidth = $('.footer-bottom > .container').width();

    const right =
        (clientWidth -
            containerOuterWidth +
            (containerOuterWidth - containerInnerWidth)) /
        2;
    backToTopButton.css({ right });
}

const pushAside = () => {
    if (
        $(window).scrollTop() + $(window).height() >
        $(document).height() - 100
    ) {
        backToTopButton.addClass('pushed');
    } else {
        backToTopButton.removeClass('pushed');
    }
};

$(() => {
    pushAside();
    moveBackToTopButton();

    backToTopButton.click(() => {
        scroll($('html, body'));
        return false;
    });

    $(window).on('scroll', throttle(pushAside, 300));
    $(window).on('resize', throttle(moveBackToTopButton, 300));
});
