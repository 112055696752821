import $ from 'jquery';

$(() => {
    function toggleContactInfo() {
        const text = $(this)
            .closest('.contact-info-top')
            .find('.contact-info-top-text');
        const link = text.find('a');
        link[0].click();
    }

    $('.js-toggle-contact-info-top').on('click', toggleContactInfo);
});
