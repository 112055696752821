export default {
    prefix: 'custom',
    iconName: 'note',
    icon: [
        98.484,
        88.488,
        [],
        null,
        'M1.244 0A1.25 1.25 0 000 1.244v86c0 .683.56 1.244 1.244 1.244h66.92a1.25 1.25 0 001.244-1.244V48.313l22.83-22.834 5.881-5.88a1.24 1.24 0 00.365-.872 1.244 1.244 0 00-.388-.862l-.008-.008-9.68-9.687a1.243 1.243 0 00-.88-.36c-.334 0-.648.13-.882.362l-5.867 5.875-11.37 11.406V1.244A1.25 1.25 0 0068.163 0zm1.244 2.488H66.92v25.45L52.117 42.74H13.074c-1.663 0-1.663 2.489 0 2.489H49.63l-8.871 8.873-.024.02c-.017.014-.03.032-.045.048l-.103.176a1.68 1.68 0 00-.094.193l.006-.02L39.92 56H13.074c-1.663 0-1.663 2.488 0 2.488h25.914l-4.092 10.813H13.074c-1.663 0-1.663 2.488 0 2.488h21.608a1.41 1.41 0 00.338-.053l.101-.027h.311l.088.021c.075.018.154.027.24.028h.136c.113-.013.224-.035.329-.07l15.586-5.965c.066-.027.132-.059.193-.096l.18-.11h.007l.05-.04L66.92 50.8V86H2.488zm85.073 8.315l7.918 7.908-4.07 4.137-7.909-7.92zm-74.487 5.386a1.25 1.25 0 00-1.244 1.245c0 .683.56 1.244 1.244 1.244h43.25c1.663 0 1.663-2.489 0-2.489zm68.62.497l7.908 7.908-38.239 38.238-7.908-7.908L53.53 44.85zM13.073 29.47c-1.663 0-1.663 2.488 0 2.488h43.25c1.663 0 1.663-2.488 0-2.488zm62.176.322c-.315 0-.637.123-.88.367L56.884 47.643l-.016.011c-1.3 1.174.577 3.058 1.756 1.762l.012-.014 17.492-17.494a1.241 1.241 0 00-.879-2.115zm-33.057 27.4l6.952 6.953-11.204 4.239z'
    ]
};
